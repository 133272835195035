import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";
import { useLocalStorage } from "./useLocalStorage";
import { APICall } from "../API";

const INITIALCONTEXT = {
  name: "<Unknown>",
  img: null,
  storeId: -1,
  terminalId: -1,
  urlApi: "https://compra-agil.d.xkw.in",
  matricula: "",
  authData: false,
};

const UserContext = createContext(INITIALCONTEXT);

function UserContextProvider({ children }) {
  const [User, updateUser] = useLocalStorage("user", INITIALCONTEXT);

  const saveUserData = (userData) => {
    updateUser({ ...User, authData: userData });
  };
  const logout = () => {
    APICall.logout();
    updateUser({ ...User, authData: false, });
  };

  const isUserOnSameStore = () => {

    return true;
    if (User.authData?.store_id === -1) return true;

    else

    return User.authData?.store_id + "" === User.storeId + "";
  };


  const isUserAdmin = () => {
    return User.authData?.is_admin;
  };

  useEffect(() => {
    APICall.getUserInfo().then((res) => {
      if (res.data && !Array.isArray(res.data)) {
        // console.log("update user ");
        // console.log(res.data);
        updateUser({
          ...User,
          authData: res.data,
        });
      } else {
        // console.log("update user ");

        // console.log(false);

        updateUser({
          ...User,
          authData: false,
        });
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        User,
        updateUser,
        saveUserData,
        isUserOnSameStore,
        isUserAdmin,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within an UserContextProvider");
  }
  return context;
}

export { UserContextProvider, useUser };
