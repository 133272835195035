import { useState } from "react";

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      
      //just for User
      //set storage to returned info
      if (keyName == 'user')
        if (typeof (newValue.authData !== null)) // authData Exists
        {
            newValue.name = newValue.authData.name;
            newValue.storeId = newValue.authData.store_id;
            newValue.terminalId = newValue.authData.user_id;
            newValue.storeName = newValue.authData.department;
        }

      window.localStorage.setItem(keyName, JSON.stringify(newValue));

    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};
