import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ReactComponent as HamburgerMenu } from "../../Assets/Icons/hamburguer.svg";
import { ReactComponent as BackArrow } from "../../Assets/Icons/ArrowLeft.svg";
import "./HeaderWithMenu.css";
import "./overide.css";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as LogOutIcon } from "../../Assets/Icons/LogOut.svg";
import { ReactComponent as DoubleBackArrow } from "../../Assets/Icons/DoubleArrowLeft.svg";
import { ReactComponent as CancelaIcon } from "../../Assets/Icons/cancela.svg";
import { useUser } from "../../Context/userContext";
import ProfilePic from "../../Assets/profile.png";
import { ReactComponent as Setting } from "../../Assets/Icons/Setting.svg";
import ModalApiConfig from "../../Screens/Modal/ModalApiConfig";
import ModalOpenCancela from "../../Screens/Modal/ModalOpenCancela";
import { useCancela } from "../../Context/CancelaContext";
const HeaderWithMenu = ({ title = "Menú", onMenuClick, onBackClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { listaCancelas,eraseCancelas } = useCancela()
  const { User, logout } = useUser();
  const location = useLocation();
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const handleMenuClick = (e) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);

    // Agregar o eliminar la clase "open" del elemento del menú
    if (!isMenuOpen) {
      menuRef.current.classList.add("open");
    } else {
      menuRef.current.classList.remove("open");
    }
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      menuRef.current.classList.remove("open");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const IconTextRow = ({ title, Icon, openModal = () => { return } }) => {
    return (
      <div onClick={() => openModal()} className="header__row__cancela">
        <div className="header__title__icon__cancela"> {Icon ? Icon : <CancelaIcon className="header__icon"></CancelaIcon>}
          <h1 className="header__cancela">{title}</h1>
        </div>
        <span
          className="mc-button__icon"

          style={{
            padding: 0,
            margin: 0,
            alignSelf: "flex-start",
            fontSize: "1.5rem",
          }}
        >
          +
        </span>
      </div>
    );
  };

  return (
    <header className={`header ${isMenuOpen ? "menu-open" : ""}`}>
      {location.pathname !== "/finish" && location.pathname !== "/menu" && (
        <div className="header__icon-left" onClick={onBackClick}>
          <BackArrow
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
      )}
      <h1 className="header__title">{title}</h1>
      {location.pathname !== "/finish" && (<div className="header__icon-right">
        <HamburgerMenu onClick={handleMenuClick} />
      </div>)}

      {isMenuOpen && (
        <div
          className={`overlay ${isMenuOpen ? "overlay-animate" : ""}`}
          onClick={handleMenuClick}
        ></div>
      )}

      <div
        className={`header__menu ${isMenuOpen ? "open" : ""}`}
        ref={menuRef}
        style={{ right: !isMenuOpen ? "-100%" : 0 }}
      >
        <div className="header__main">
          <div className="header__back_arrow_div">
            <div></div>
            <DoubleBackArrow onClick={handleMenuClick}> </DoubleBackArrow>
          </div>
          <h1 className="header__cancela">Abertura Manual Cancelas</h1>
          <div className="header__divider" />
          <div className="header__container">

            {listaCancelas.map((item, index) => (
              <IconTextRow openModal={() => setModalOpen(item)} title={"Abrir Cancela " + item.lane}></IconTextRow>
            ))}
          </div>

          {User.authData?.is_admin &&
            <div className="header__settings" onClick={() => setIsHidden(false)}>
              <div className="header__divider" style={{ marginBottom: 30 }} />
              <IconTextRow
                Icon={<Setting className="header__icon"></Setting>}
                title="Definições"
              ></IconTextRow>
            </div>}
            
        </div>
        <div className="header__bottom">
          <div className="header__image_text">
            <img
              src={ProfilePic}
              className="imageProfile"
            ></img>
            <div>
              <h3 className="NameTitle">{User.authData?.name}</h3>
              <p className="NameDesc">Leroy Merlin {User.storeName}</p>
            </div>
          </div>
          <div className="header__loggout__icon" style={{ cursor: "pointer" }} onClick={() => {
            logout()
            eraseCancelas()
          }}>
            <LogOutIcon></LogOutIcon>
          </div>
        </div>
      </div>
      <ModalApiConfig onClose={() => setIsHidden(true)}
        isHidden={isHidden} />
      <ModalOpenCancela isHidden={modalOpen === false} cancelaNumber={modalOpen} onClose={() => setModalOpen(false)}></ModalOpenCancela>
    </header>
  );
};

HeaderWithMenu.propTypes = {
  title: PropTypes.string.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default HeaderWithMenu;
